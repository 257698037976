import { complete } from './complete';
import * as xhr from './xhr';
import debounce from 'debounce-promise';
export function userComplete(opts) {
    const debounced = debounce((term) => xhr
        .json(xhr.url('/api/player/autocomplete', {
        term,
        friend: opts.friend ? 1 : 0,
        tour: opts.tour,
        swiss: opts.swiss,
        team: opts.team,
        object: 1,
    }))
        .then((r) => ({ term, ...r })), 150);
    complete({
        input: opts.input,
        fetch: t => debounced(t).then(({ term, result }) => (t == term ? result : Promise.reject('Debounced ' + t))),
        render(o) {
            const tag = opts.tag || 'a';
            return ('<' +
                tag +
                ' class="complete-result ulpt user-link' +
                (o.online ? ' online' : '') +
                '" ' +
                (tag === 'a' ? '' : 'data-') +
                'href="/@/' +
                o.name +
                '">' +
                '<i class="line' +
                (o.patron ? ' patron' : '') +
                '"></i>' +
                (o.title
                    ? '<span class="utitle"' +
                        (o.title == 'BOT' ? ' data-bot="data-bot" ' : '') +
                        '>' +
                        o.title +
                        '</span>&nbsp;'
                    : '') +
                o.name +
                (o.flair ? '<img class="uflair" src="' + site.asset.flairSrc(o.flair) + '"/>' : '') +
                '</' +
                tag +
                '>');
        },
        populate: opts.populate || (r => r.name),
        onSelect: opts.onSelect,
        regex: /^[a-z][\w-]{2,29}$/i,
    });
    if (opts.focus)
        setTimeout(() => opts.input.focus());
}
